import { availableNetworks, reactAppBuildType } from "default-variables";
import { useCallback } from "react";

// [ ] This can be moved in now
// This sits outside of the hook to ensure the BlockNative Onboard init can load the networks, as it's run globally and therefor ineligible to call a hook.
const walletConnectableNetworks = () => {
    if (import.meta.env.VITE_BUILD_TARGET !== "recurring") {
        return availableNetworks;
    }

    if (!reactAppBuildType) {
        throw new Error("Missing ENV variable: VITE_BUILD_TYPE");
    }

    // If this is the Recurring Swap app, get the contract address for this build, filter out networks without a contract
    return availableNetworks
        .map((network) => {
            return {
                ...network,
                contractAddress:
                    network.recurringSwapContract[reactAppBuildType] || null,
            };
        })
        .filter(({ contractAddress }) => contractAddress);
};

// [ ] This doesn't have to be a hook anymore
const useAvailableNetworks = () => {
    const getAvailableNetworks = useCallback(walletConnectableNetworks, []);

    const getNetworkById = useCallback(
        (networkId: string) => {
            const networks = getAvailableNetworks();

            const network = networks.find(
                (network) => network.networkId === networkId // && network.chain === block
            );

            return network;
        },
        [getAvailableNetworks]
    );

    const getNetworkByContract = (contractAddress: string) => {
        const networks = getAvailableNetworks();

        const network = networks.find(
            (network) =>
                network.contractAddress &&
                network.contractAddress.toLowerCase() ===
                    contractAddress.toLowerCase()
        )?.networkId;

        if (!network) {
            throw new Error(
                `This contract is not available on the network you have selected.`
            );
        }
        return network;
    };

    return {
        getAvailableNetworks,
        getNetworkById,
        getNetworkByContract,
    };
};

export { useAvailableNetworks, walletConnectableNetworks };
